@import "constant";
@import "./components/index";
@import "~bootstrap/scss/bootstrap";

* {
  box-sizing: border-box;
}

body {
  background-color: $background-color;
  margin: 0;
  padding: 0;
  color: white;
  @include font-size(1.1rem);
}

main {
  padding: 15px;
}
