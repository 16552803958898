.logo {
  height: 50px;
  padding: 5px 0;
  font-weight: bold;

  img {
    height: 100%;
    display: inline-block;
    margin-right: 10px;
  }
}
