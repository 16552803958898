@import "~bootstrap/scss/bootstrap";

.jumbotron {
  min-height: calc(100vh - 66px);
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    color: white;
    text-decoration: none;
  }

  p.hello {
    color: $orange;
    margin-bottom: 5px;
  }

  p.name {
    @include rfs(2.4em, font-size);
  }

  p.who {
    color: $yellow;
  }

  p.description {
    text-align: justify;
  }

  .bar {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 50px;
    margin-bottom: 20px;

    .social {
      margin: 0 20px;
    }

    img {
      width: 25px;
      display: inline-block;
      margin: 0 8px;
    }
  }
}

.picture {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  transform: translateY(-40px);

  img {
    width: 100%;
    max-width: 400px;

    @include media-breakpoint-down(md) {
      width: 80%;
    }
  }

  @include media-breakpoint-down(md) {
    transform: translateY(0px);
  }
}
